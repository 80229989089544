<template>
  <div> 

  <v-sheet

    class="nxmv-model-banner-sheet nxmv-model-banner-transparent"
  >
  <FilterWithChips v-if="this.searchedSiteId == null"
    class="nxmv-model-banner-filter"
    @selected-option="handleSelectedOption"
    :selection="getSiteNames()"
  />
  <v-slide-group 
    v-if="sortedGaps().length == 0"
    show-arrows
    class="ma-10 nxmv-model-banner-transparent">
    <ModelBanner class="nxmv-place-holder" :model-to-display="car('4c34821d-2688-ed11-81ad-000d3ad97e61')"></ModelBanner>
    <ModelBanner class="nxmv-place-holder" :model-to-display="car('b28bf70e-a890-e811-8145-5065f38b46a1')"></ModelBanner>
    <ModelBanner class="nxmv-place-holder" :model-to-display="car('31f9f175-b7d8-eb11-bacb-000d3addc725')"></ModelBanner>
  </v-slide-group>
 
  <v-slide-group 
      ref="slideGroup"
      show-arrows
      class="ma-10 nxmv-model-banner-transparent"
      mobile-breakpoint ="md"
      v-if="this.searchedSiteId != null || this.searchedModelId != null && sortedGaps().length != 0"
    >
      <div 
        v-for="gap in sortedGaps()"
        :key="gap.vehicleid"
        @click="selectGap(gap)"
        >
          <ModelBanner  :model-to-display="car(gap.modelId)"  :rent-gap="gap" />
      </div>
    </v-slide-group>
  </v-sheet>
  <!-- <v-btn @click="scrollToItem()">Scroll to Item 2</v-btn> -->

  <div
      v-if="showModal"
      class="nxmv-modal"
      @click="showModal = false"
    >
      <div
        class="nxmv-close-button"
        @click="showModal = false"
      >
        <FontAwesomeIcon icon="times" />
      </div>
      <div
        ref="modalContent"
        class="nxmv-modal-content"
      >
        <iframe
          style="border: none; width: 100%; height: 3000px;"
          :src="this.selectedPath"
        />
      </div>
    </div>
</div>
</template>

<script>
import ModelBanner from "@/components/ui/ModelBanner.vue";
import {mapActions, mapGetters, mapMutations} from "vuex";
import FilterWithChips from "@/components/ui/FilterWithChips"
export default {
  name: "EmbedModelBanner",
  components: {
    ModelBanner,
    FilterWithChips,
  },

  data: () => ({
    selectedLocation: "",
    availableLocations: {},
    showAllLocations: false,
 
    startDate: null,
    endDate: null,
    showModal: false,
    selectedPath: "",
    searchedModelId: null,
    searchedSiteId: null,
  
  }),
  mounted() {
    // document.addEventListener('click', this.handleClickOutside);
    // document.addEventListener('touchstart', this.handleClickOutside);
    window.addEventListener('message', this.handleIframeMessage);
  },
  beforeUnmount() {
    // document.removeEventListener('click', this.handleClickOutside);
    // document.removeEventListener('touchstart', this.handleClickOutside);
    window.removeEventListener('message', this.handleIframeMessage);
  },

  props: {
    siteId: {
      type: String,
      default: null,
    },
    modelId: {
      type: String,
      default: null,
    },
  
  },
  computed: {
    ...mapGetters({
      gaps: "gaps",
      sites: "sites",
      models: "models",
      isAvailable: "isAvailable",
      captchaWebsiteKey: "captchaWebsiteKey",
    }),
  }, 

  async beforeMount() {
 
    this.searchedSiteId = this.siteId;
    await this.LOAD_GAPS_DATA();
    this.availableLocations = this.sortedGaps();
    this.readQueryParameters();
  },
  async created() {
    document.documentElement.setAttribute("lang", this.$i18n.locale);
    try {
      await this.INITIALIZE_APP();
    } catch (err) {
      this.UPDATE_ERROR({ error: err });
    }
  },
methods: {
  ...mapActions({
      LOAD_GAPS_DATA: "LOAD_GAPS_DATA",
      POST_CAPTCHA: "POST_CAPTCHA",
      INITIALIZE_APP: "INITIALIZE_APP",
    }),
  ...mapMutations({
    SET_LAST_GAP_SEEN: "SET_LAST_GAP_SEEN",
  }),

  car(modelId) {
    return this.$store.getters.models.find((x) => x.modelId === modelId);
  },
  
  sortedGaps() {
      let gapArray = new Array();
      this.gaps.forEach((gap) => {
        if (this.siteId != null){ // looked using siteId
          if (gap.siteName == this.selectedLocation){
            gapArray.push(gap);
            gapArray.sort((a,b) => {
              if (a.startDate < b.startDate) {
                return -1
              }
              if (a.startDate > b.startDate) {
                return 1
              }
              return 0
              })
            }
        }
        if(this.searchedModelId != null){
          if((gap.siteName == this.selectedLocation || this.showAllLocations) && gap.modelId == this.searchedModelId){
            gapArray.push(gap);
            gapArray.sort((a,b) => {
              if (a.startDate < b.startDate) {
                return -1
              }
              if (a.startDate > b.startDate) {
                return 1
              }
              return 0
              })
            }
          }
    
      });
      return gapArray;
    },
    getSiteNames() {
      let siteArray = [];
      siteArray.push(this.$t('rentGapsPage.showAll'));
      this.sites.forEach((site) => {
        siteArray.push(this.outputSiteName(site.siteId));
      });
      return siteArray.sort();
    },
    // handleClickOutside(event) {
    //   if (!this.calendarPosition) {
    //     return;
    //   }
    //   if (this.$refs.calendarButton && this.$refs.calendarButton.contains(event.target)) {
    //     return;
    //   }
    //   if (this.$refs.calendarDropdown && !this.$refs.calendarDropdown.contains(event.target)) {
    //     this.calendarPosition = null;
    //   }
    // },
    handleSelectedOption(payload) {
      this.selectedLocation = payload;
      if (this.selectedLocation == this.getSiteNames()[0]){
        this.showAllLocations = true;
      }else{
        this.showAllLocations = false;
      }
      this.availableLocations = this.sortedGaps()

      // Resets the slide group to the fist element
      const slideGroup = this.$refs.slideGroup;
      slideGroup.scrollOffset = 0;
    },
    outputSiteName(siteId) {
      return this.sites.find((elem) => elem.siteId === siteId)?.name;
    },

    scrollToItem() {
      const slideGroup = this.$refs.slideGroup;
      slideGroup.scrollOffset = 0;
    },
    handleIframeMessage(event) {
      if (event.data != "scrollToTop")
        return

      if (this.$refs.modalContent) {
        this.$refs.modalContent.scrollTop = 0;
      }
    },
    
    selectGap(gap){
      console.log("Clicked Model ID: ",gap.modelId);
      let path = window.apiEndpoint ? window.apiEndpoint : "";
      path += "/#/booking/details?fullWidth=true";
      if (gap.modelId) { path += "&modell=" + gap.modelId; }
      if (gap.modelVariantId) { path += "&variant=" + gap.modelVariantId; }
      if (gap.siteId) { path += "&site=" + gap.siteId; }
      if (gap.startDate) { path += "&startDate=" + gap.startDate; }
      if (gap.endDate) { path += "&endDate=" + gap.endDate; }
      // if (this.referral) { path += "&referral=" + this.referral; }
      this.selectedPath = path;
      this.showModal = true;
    },
    readQueryParameters(){
      if (this.siteId) {
      this.selectedLocation = this.outputSiteName(this.siteId);
      this.searchedSiteId = this.siteId;
      }
      if(this.modelId) {
        this.showAllLocations = true;
        this.searchedModelId = this.modelId;
      }
      // if (urlParams.has("siteId")) {
      // this.searchedSiteId =urlParams.get("siteId");
      // this.selectedLocation = this.outputSiteName(this.searchedSiteId);
      // console.log("Searched Site ID: ", this.selectedLocation);
      // }
      // if(urlParams.has("modelId")) {
      //   this.showAllLocations = true;
      //   this.searchedModelId = urlParams.get("modelId");
      //   console.log("Model ID searched", this.searchedModelId);
      // }
  
      
    },
    isMobile() {
        if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
          console.log("MOBILE!")
          return true
        } else {
          console.log("desktop")
          return false
        }
      },
    
  },

}
</script>

<style scoped>
.nxmv-model-banner-sheet{
  width: 1000px;
  /* width: 96vw; */
}
.nxmv-model-banner-filter{
  margin-left: 20px;
}
.nxmv-model-banner-transparent{
  background-color: rgba(255, 255, 255, 0);
  justify-content: center;
}

.nxmv-place-holder{
  width: fit-content;
}

@media only screen and (max-width: 800px){
  .nxmv-model-banner-sheet{
    /* width: calc(96vw - 2%); */
    width: 96vw;
  }
}
</style>